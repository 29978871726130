import { BlockType, CoverFormat, getFileURL, ImageProxyResize, PostType } from 'a4bd-meta';
import { Col, Form, Input, Row } from 'antd';
import { always, cond, equals, prop, T } from 'ramda';
import React, { FC, useContext, useMemo, useState } from 'react';

import DefaultImage from '~assets/png/defaultImage.png';
import { DropType } from '~constants';
import { useIsMobile, useIsTablet, usePost } from '~hooks';
import { ConfigContext } from '~providers';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { SvgFilter } from '../../../components/SvgFilter';
import { DropZone } from '../../DropZone';
import { isInProgress } from '../../DropZone/utils';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

export const CoverBlock: FC<BlockProps<BlockType.Cover>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const { sections, type } = usePost();
  const [form] = Form.useForm();
  const { content, files } = block;

  const [imageFile, setImageFile] = useState(getFileById(content.fileId, files));

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const currentSection = sections?.[0];
  const formatValues: FormBlockProps<BlockType.Cover>['formatValues'] = (content) => ({
    content,
    fileIds: [content.fileId].filter(isNotNil),
  });

  const getHeight = () => {
    let height = 273;
    if (isTablet) height = 155;
    if (isMobile) height = 107;
    return height;
  };

  const getWidth = () => {
    let height = 880;
    if (isTablet) height = 620;
    if (isMobile) height = 345;
    return height;
  };

  const imageOptions = {
    height: getHeight(),
    resize: ImageProxyResize.Fill,
    width: getWidth(),
  };

  const { imageProxyHost } = useContext(ConfigContext);
  const src = imageFile && getFileURL(imageFile, { ...imageOptions, domain: imageProxyHost });

  const getHideCaption = cond([
    [equals(PostType.Spec), always(true)],
    [T, always(false)],
  ]);

  const getOptionInitialValue = cond([
    [equals(PostType.Card), always(CoverFormat.CaptionUnder)],
    [equals(PostType.Test), always(CoverFormat.CaptionAbove)],
    [T, always(CoverFormat.CaptionUnder)],
  ]);

  const initialValues = useMemo(() => {
    const values = { ...content };

    if (type === PostType.Card || type === PostType.Test) {
      values.format = getOptionInitialValue(type);
    }

    return {
      ...values,
    };
  }, []);

  const isFileLoaded = imageFile && !isInProgress(imageFile);

  return (
    <>
      <SvgFilter darkColor={currentSection?.darkColor} lightColor={currentSection?.lightColor} />
      <FormBlock
        block={block}
        blockIndex={blockIndex}
        form={form}
        initialValues={initialValues}
        formatValues={formatValues}
        canDelete={canDelete}
      >
        <Row justify="center" gutter={[30, 30]}>
          <Col span={24}>
            <Row className={styles.title} justify="space-between">
              <Col xs={12}>Обложка</Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row className={styles.subtitle} justify="space-between">
              <Col xs={12}>Стандартная обложка</Col>
            </Row>
            <Form.Item
              name="fileId"
              getValueFromEvent={prop('id')}
              getValueProps={() => ({
                value: getFileById(content.fileId, files),
              })}
            >
              <DropZone
                blockType={block.type}
                className={styles.dropZone}
                type={DropType.Image}
                small={isTablet || isMobile}
                imageOptions={imageOptions}
                setImageFile={setImageFile}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Row className={styles.subtitle} justify="space-between">
              <Col xs={12}>Двухцветная обложка</Col>
            </Row>
            <div
              className={styles.dropZone}
              style={{
                height: `${getHeight()}px`,
                margin: '0 auto',
                width: `${getWidth()}px`,
              }}
            >
              <img
                src={(isFileLoaded && src) || DefaultImage}
                alt=""
                style={{
                  filter: isFileLoaded && src ? `url(#cover_filter)` : undefined,
                  height: '100%',
                  objectFit: 'cover',
                  width: '100%',
                }}
              />
            </div>
          </Col>

          <Col xs={24}>
            <div className={styles.captionWrapper}>
              <div className={styles.captionTitle}>Подпись к обложке</div>
              <div className={styles.caption}>
                <Form.Item
                  name="caption"
                  className={styles.captionItem}
                  hidden={getHideCaption(type)}
                >
                  <Input bordered={false} className={styles.input} placeholder="Добавьте подпись" />
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
      </FormBlock>
    </>
  );
};
