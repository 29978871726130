import { BlockType } from 'a4bd-meta';
import { Col, Form, Input, Row } from 'antd';
import cx from 'classnames';
import parse from 'html-react-parser';
import loadScript from 'load-script';
import { identity } from 'ramda';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { BlockProps } from '~types';
import { getTypeWidget, isDefined, WidgetType } from '~utils';

import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;
export const widgetScripts = {
  [WidgetType.facebook]: {
    crossorigin: 'anonymous',
    id: 'facebook-wjs',
    nonce: 'SRzOQrHN',
    src: 'https://connect.facebook.net/ru_RU/sdk.js#xfbml=1&version=v15.0',
  },
  [WidgetType.inst]: {
    id: 'inst-wjs',
    src: 'https://platform.instagram.com/en_US/embeds.js',
  },
  [WidgetType.twitter]: {
    id: 'twitter-wjs',
    src: 'https://platform.twitter.com/widgets.js',
  },
};

export const WidgetBlock: FC<BlockProps<BlockType.Widget>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { content } = block;
  const { html: defaultValue } = content || {};
  const [aspectRatio, setAspectRatio] = useState<string | undefined>(undefined);

  const preview = Form.useWatch('html', form);

  const initialValues = useMemo(
    () => ({
      ...content,
    }),
    [],
  );

  const html = preview || defaultValue;

  const type = getTypeWidget(html) as WidgetType;

  useEffect(() => {
    if (type === WidgetType.script) {
      const parsedHtml = parse(html) as { props: { id: string; src: string } };
      const src = parsedHtml?.props?.src || null;
      const id = parsedHtml?.props?.id || null;
      if (isDefined(id) && isDefined(src)) {
        loadScript(src, { attrs: { id } }, identity);
      }
      return;
    }

    if (type === WidgetType.newsmedia) {
      setAspectRatio('ratio_9_16');
      return;
    }

    const script = widgetScripts[type];
    if (script) {
      loadScript(script.src, { attrs: { id: script.id } }, identity);
    }
  }, [html, type]);

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={initialValues}
      canDelete={canDelete}
    >
      <Row gutter={[20, 20]}>
        <Col>
          <div className={styles.title}>Код виджета</div>
        </Col>
        <Col span={24}>
          <div className={styles.subtitle}>Добавьте виджет</div>
          <div className={styles.codeWrapper}>
            <Form.Item name="html">
              <TextArea
                bordered={false}
                placeholder="Код виджета"
                className={styles.input}
                autoSize
                rows={1}
              />
            </Form.Item>
          </div>
        </Col>
        <Col span={24}>
          <div className={styles.subtitle}>Предпросмотр</div>
          <div
            className={styles.preview}
            style={{
              aspectRatio,
            }}
          >
            {html && (
              <div
                className={cx(styles.html, aspectRatio && styles[aspectRatio])}
                dangerouslySetInnerHTML={{ __html: html }}
              />
            )}
          </div>
        </Col>
      </Row>
    </FormBlock>
  );
};
