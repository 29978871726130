import { PostStatus, PostType, SectionAlias, SectionStatus } from 'a4bd-meta';
import { Col, Form, Row, Select } from 'antd';
import classNames from 'classnames';
import { formatISO, parse } from 'date-fns';
import { __, allPass, applySpec, compose, includes, isEmpty, not, pipe, prop, propEq } from 'ramda';
import React, { useEffect } from 'react';

import { Button, DatePicker, Visible } from '~components';
import { dateFormat } from '~constants';
import { useGetAuthorsListQuery, useGetSectionsListQuery } from '~services';
import { getStatusTitle, getTypeTitle, isDefined, mapQueryIdsToIdArray } from '~utils';

import styles from './styles.module.scss';

const { Option } = Select;

type PostsFilterValues = {
  authors?: number[];
  publishedAt?: string;
  sections?: number[];
  term?: string;
  type?: PostType;
};

interface Props {
  inDrawer?: boolean;
  isFixedType?: boolean;

  onChange?(values: any): void;

  resetFilter: () => void;
  values?: PostsFilterValues;
}

export const PostsFilter: React.FC<Props> = ({
  inDrawer = false,
  isFixedType = false,
  onChange,
  resetFilter,
  values,
}) => {
  const [form] = Form.useForm<PostsFilterValues>();
  const { resetFields } = form;
  const { data: authors } = useGetAuthorsListQuery({});
  const { data: sections } = useGetSectionsListQuery({ relations: ['grid'] });

  const sectionsOptions = (sections?.results || []).filter(
    // Убираем неопубликованные разделы
    allPass([
      pipe(
        prop('alias'),
        includes(__, [
          SectionAlias.Stories,
          SectionAlias.Things,
          SectionAlias.Context,
          SectionAlias.Podcasts,
          SectionAlias.People,
          SectionAlias.Trends,
          SectionAlias.Tests,
          SectionAlias.Quizes,
        ]),
      ),
      propEq('status', SectionStatus.published),
    ]),
  );

  const authorsOptions = authors?.results ?? [];

  useEffect(() => {
    if (isEmpty(values)) {
      resetFields();
    }
  }, [resetFields, values]);

  const feedsOptions = [{ name: 'VK' }, { name: 'OK' }];

  useEffect(() => {
    const authorIds = values?.authors || [];

    form.setFieldValue('authors', mapQueryIdsToIdArray(authorIds));
  }, [form, values?.authors]);

  const onClear = () => {
    resetFilter();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onChange}
      className={styles.wrapper}
      initialValues={values}
    >
      <Row gutter={[15, 20]} className={classNames(inDrawer && styles.drawerContent)}>
        <Visible isVisible={!isFixedType}>
          <Col xs={24} sm={24} md={12} lg={6}>
            <Form.Item label="Тип публикации" name="type">
              <Select placeholder="Выбрать" size="large" allowClear>
                {Object.values(PostType)
                  .filter(
                    includes(__, [
                      PostType.Article,
                      PostType.Story,
                      PostType.Podcast,
                      PostType.Thing,
                      PostType.Custom,
                    ]),
                  )
                  .map((value) => (
                    <Option key={value} value={value}>
                      {getTypeTitle(value)}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Visible>

        <Col xs={24} sm={24} md={12} lg={6}>
          <Form.Item
            label="Рубрики"
            name="sections"
            getValueProps={applySpec({ value: mapQueryIdsToIdArray })}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                isDefined(option) && option.title.toLowerCase().includes(input.toLowerCase())
              }
              options={sectionsOptions}
              fieldNames={{ label: 'title', value: 'id' }}
              placeholder="Выбрать"
              size="large"
              mode="multiple"
              allowClear
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6}>
          <Form.Item label="Статус" name="status">
            <Select placeholder="Выбрать" size="large" allowClear>
              {Object.values(PostStatus)
                .filter(compose(not, includes(__, [PostStatus.Archived])))
                .map((value) => (
                  <Option key={value} value={value}>
                    {getStatusTitle(value)}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6}>
          <Form.Item
            label="Автор"
            name="authors"
            getValueProps={applySpec({ value: mapQueryIdsToIdArray })}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                isDefined(option) && option.name.toLowerCase().includes(input.toLowerCase())
              }
              options={authorsOptions}
              fieldNames={{ label: 'name', value: 'id' }}
              placeholder="Выбрать"
              size="large"
              allowClear
              mode="multiple"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6}>
          <Form.Item
            label="Создано"
            name="createdAt"
            getValueFromEvent={(value: Date) =>
              value ? formatISO(value, { format: 'extended', representation: 'date' }) : undefined
            }
            getValueProps={(value: string) => ({
              value: value ? parse(value, dateFormat, new Date()) : undefined,
            })}
          >
            <DatePicker
              size="large"
              format={dateFormat}
              style={{ width: '100%' }}
              inputReadOnly
              allowClear
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6}>
          <Form.Item
            label="Изменено"
            name="updatedAt"
            getValueFromEvent={(value: Date) =>
              value ? formatISO(value, { format: 'extended', representation: 'date' }) : undefined
            }
            getValueProps={(value: string) => ({
              value: value ? parse(value, dateFormat, new Date()) : undefined,
            })}
          >
            <DatePicker
              size="large"
              format={dateFormat}
              style={{ width: '100%' }}
              inputReadOnly
              allowClear
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6}>
          <Form.Item
            label="Опубликовано"
            name="publishedAt"
            getValueFromEvent={(value: Date) =>
              value ? formatISO(value, { format: 'extended', representation: 'date' }) : undefined
            }
            getValueProps={(value: string) => ({
              value: value ? parse(value, dateFormat, new Date()) : undefined,
            })}
          >
            <DatePicker
              size="large"
              format={dateFormat}
              style={{ width: '100%' }}
              inputReadOnly
              allowClear
            />
          </Form.Item>
        </Col>

        <Visible isVisible={false}>
          <Col xs={24} sm={24} md={12} lg={6}>
            <Form.Item name="feeds" label="RSS" labelCol={{ span: 24 }}>
              <Select
                mode="multiple"
                size="large"
                filterOption={(input, option) =>
                  isDefined(option) && option.name.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Выбрать"
                maxTagCount="responsive"
                allowClear
                options={feedsOptions}
                fieldNames={{ label: 'name', value: 'id' }}
              />
            </Form.Item>
          </Col>
        </Visible>
      </Row>
      <Row justify="end">
        <Col className={styles.buttonWrapper}>
          <Button size="large" onClick={onClear}>
            Сбросить
          </Button>

          <Button htmlType="submit" size="large" type="primary">
            Применить
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
