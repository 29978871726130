import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  CreateGridSchemaBodySecure,
  GetManyGridSchemasResponseSecure,
  GridSchema,
  UpdateGridSchemaBodySecure,
  UpdateManyGridSchemasBodySecure,
} from 'a4bd-meta';

import { ApiPath } from '~constants';
import { createUrl } from '~utils';

// eslint-disable-next-line import/no-cycle
import { authGuardQuery } from './defaultApi';

export const gridSchemasApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    createGridSchema: builder.mutation<GridSchema, Partial<CreateGridSchemaBodySecure>>({
      invalidatesTags: [{ id: 'List', type: 'GridSchemas' }],
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.GridSchemas),
      }),
    }),
    getGridSchemasList: builder.query<
      GetManyGridSchemasResponseSecure,
      PaginationParams & SortingParams & RelationsParams & Partial<GridSchema>
    >({
      providesTags: () => [{ id: 'List', type: 'GridSchemas' }],
      query: (params = {}) => ({
        method: 'GET',
        params,
        url: createUrl(ApiPath.GridSchemas),
      }),
    }),
    updateGridSchema: builder.mutation<
      GridSchema,
      { changes: UpdateGridSchemaBodySecure; id: number }
    >({
      invalidatesTags: [{ id: 'List', type: 'GridSchemas' }],
      query: (data) => ({
        data: data.changes,
        method: 'PATCH',
        url: createUrl(ApiPath.GridSchemas, data.id),
      }),
    }),
    updateGridSchemas: builder.mutation<void, UpdateManyGridSchemasBodySecure>({
      query: (data) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.GridSchemas),
      }),
    }),
  }),
  reducerPath: 'gridSchemasApi',
  tagTypes: ['GridSchemas'],
});

export const {
  useCreateGridSchemaMutation,
  useGetGridSchemasListQuery,
  useUpdateGridSchemaMutation,
  useUpdateGridSchemasMutation,
} = gridSchemasApi;
