import { PostPreviewSecure, PostStatus, PostType } from 'a4bd-meta';
import { Card, Col, message, Row, Skeleton, Space, Typography } from 'antd';
import classNames from 'classnames';
import { stringify } from 'query-string';
import React, { useCallback, useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Edit, Eye, Social } from '~assets';
import { Badge, Button, Cover, Visible } from '~components';
import { BadgeColor, PublicRoutesPath, RoutesPath } from '~constants';
import { ConfigContext } from '~providers';
import { useCreatePostPreviewTokenMutation } from '~services';
import {
  deleteMarkup,
  formatDate,
  getStatusIcon,
  getStatusTitle,
  getTypeTitle,
  isArticle,
  isNews,
  isSuccessResult,
} from '~utils';

import styles from './styles.module.scss';

interface Props<T extends PostType = PostType> {
  className?: string;
  loading?: boolean;

  onClick?(post: PostPreviewSecure<T>): void;

  post: PostPreviewSecure<T>;
  shortInfo?: boolean;
}

const displayedFormatDate = 'dd MMMM yyyy, HH:mm';

export const PostCard: React.FC<Props> = ({
  className,
  loading = false,
  onClick,
  post,
  shortInfo = false,
}) => {
  const [createPostPreviewToken, { isLoading: isCreatingPostPreviewToken }] =
    useCreatePostPreviewTokenMutation();
  const { previewUrl, publicOriginUrl } = useContext(ConfigContext);
  const navigate = useNavigate();

  const onCardClick = useCallback(() => {
    if (onClick) onClick(post);
  }, [onClick, post]);

  if (loading) {
    return (
      <Card>
        <Row>
          <Col span={24}>
            <Skeleton.Node active />
          </Col>
          <Col span={24}>
            <Skeleton.Input active block />
          </Col>
        </Row>
      </Card>
    );
  }

  const { alias, createdAt, id, preview, publishedAt, title, type, updatedAt } = post;

  const onEdit = () => {
    navigate(generatePath(RoutesPath.PostEdit, { id }));
  };

  const handlePreview = async () => {
    const result = await createPostPreviewToken({ expiresIn: '1h', id });
    if (isSuccessResult(result)) {
      const { token } = result.data;
      window.open(
        `${previewUrl}${generatePath(PublicRoutesPath.PostPreview, { postId: id })}?${stringify({
          token,
        })}`,
        '_blank',
      );
    } else {
      message.error('Ошибка получения токена');
    }
  };

  const onCopyLink = () => {
    const linkToPublication = `${publicOriginUrl}/a/${alias || id}`;
    navigator.clipboard.writeText(linkToPublication);
  };

  const canPreview = isArticle(type);
  const hasLink = isArticle(type);
  const isPublished = post.status === PostStatus.Published;

  return (
    <Card
      hoverable={!!onClick}
      cover={
        !isNews(type) && (
          <Cover src={preview?.imageURLs?.origin.share.desktop} width="100%" objectFit="cover" />
        )
      }
      className={classNames(styles.card, styles[type], className)}
      onClick={onCardClick}
      bodyStyle={{ height: '100%', padding: '15px 0' }}
    >
      <Row className={styles.body}>
        <Col span={24}>
          <Row gutter={[0, 24]} className={styles.content}>
            <Col span={24}>
              <Col span={24}>
                <Typography.Title level={5} className={styles.title}>
                  {deleteMarkup(title?.text)}
                </Typography.Title>
              </Col>
            </Col>
          </Row>
        </Col>
        <Col>
          <Space direction="vertical" style={{ width: '100%' }}>
            {shortInfo ? (
              <Col span={24} className={styles.content}>
                <Typography.Text className={styles.date}>
                  {formatDate(publishedAt, displayedFormatDate)}
                </Typography.Text>
              </Col>
            ) : (
              <>
                <Row gutter={[0, 15]} className={styles.content}>
                  <Col>
                    <Row>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className={styles.date}>Создано:</Typography.Text>
                          </Col>
                          <Col>
                            <Typography.Text className={styles.date}>
                              {formatDate(createdAt, displayedFormatDate)}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Col>
                      {publishedAt && (
                        <Col span={24}>
                          <Row justify="space-between">
                            <Col>
                              <Typography.Text className={styles.date}>
                                Опубликовано:
                              </Typography.Text>
                            </Col>
                            <Col>
                              <Typography.Text className={styles.date}>
                                {formatDate(publishedAt, displayedFormatDate)}
                              </Typography.Text>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className={styles.date}>Изменено:</Typography.Text>
                          </Col>
                          <Col>
                            <Typography.Text className={styles.date}>
                              {formatDate(updatedAt, displayedFormatDate)}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify="space-between" className={styles.actions}>
                  <Col className={styles.editButtonWrapper}>
                    <Button icon={<Edit />} onClick={onEdit} className={styles.editButton}>
                      Редактировать
                    </Button>
                  </Col>
                  <Col>
                    <Space>
                      <Visible isVisible={canPreview}>
                        <Button
                          icon={<Eye />}
                          iconFillType="stroke"
                          loading={isCreatingPostPreviewToken}
                          className={styles.squareButton}
                          onClick={handlePreview}
                        />
                      </Visible>
                      <Visible isVisible={hasLink}>
                        <Button
                          className={classNames(styles.squareButton)}
                          disabled={!isPublished}
                          icon={<Social />}
                          onClick={onCopyLink}
                        />
                      </Visible>
                    </Space>
                  </Col>
                </Row>
              </>
            )}
          </Space>
        </Col>
      </Row>

      <Badge className={styles.type} color={BadgeColor.Blue}>
        {getTypeTitle(post.type)}
      </Badge>
      <Badge className={styles.status} color={BadgeColor.BlueLight}>
        {getStatusIcon(post.status)}
        {getStatusTitle(post.status)}
      </Badge>
    </Card>
  );
};
