export const RoutesPath = {
  Articles: '/articles',
  AuthCallback: `/auth/callback`,
  Catalogs: '/catalogs',
  EmployeeEdit: '/employees/:id',
  Employees: '/employees',
  GridSchema: '/sections/:sectionId/grid/:gridId/schema',
  GridSchemas: '/grid-schemas',
  Grids: '/grids',
  Home: '/',
  Login: '/login',
  NotFound: '/404',
  Podcasts: '/podcasts',
  PostEdit: '/posts/:id',
  Posts: '/posts',
  Sections: '/sections',
  Settings: '/settings',
  Stories: '/stories',
  Things: '/things',
};

export const PublicRoutesPath = {
  PostPreview: '/a/:postId',
};
