import { InfoCircleOutlined } from '@ant-design/icons';
import { PostStatus } from 'a4bd-meta';
import { Col, Form, FormInstance, Input, Row, Select, Tooltip } from 'antd';
import { parseISO } from 'date-fns/fp';
import { __, always, anyPass, applySpec, evolve, ifElse, includes, isNil, map, prop } from 'ramda';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';

import { DatePicker, Visible } from '~components';
import { useHasPermission, usePost } from '~hooks';
import { userSelector } from '~selectors';
import { useGetAuthorsListQuery, useGetSectionsListQuery, useUpdatePostMutation } from '~services';
import { isArticle, isDefined, isPodcast, isStory, isThing, noop } from '~utils';

import styles from './styles.module.scss';

const isSectionDisabled = anyPass([isStory, isPodcast, isThing]);

const getInitialValues = evolve({
  authors: map(prop('id')),
  feeds: map(prop('id')),
  publishedAt: ifElse(isNil, always(null), parseISO),
  sections: map(prop('id')),
  tags: map(prop('id')),
});

const getUpdateValues = applySpec({
  alias: prop('alias'),
  authorIds: prop('authors'),
  feedIds: prop('feeds'),
  sectionIds: prop('sections'),
  tagIds: prop('tags'),
  type: prop('type'),
});

const isPostPublished = includes(__, [PostStatus.Published, PostStatus.Postponed]);

type PostSettingsProps = {
  form: FormInstance<any>;
};

export const PostSettings: FC<PostSettingsProps> = ({ form }) => {
  const post = usePost();
  const { id: postId, status, type } = post;

  const currentType = type;
  const formValues = Form.useWatch([], form);

  const [updatePost] = useUpdatePostMutation();

  const hasPermission = useHasPermission();
  const { id: employeeId } = useSelector(userSelector) || {};
  const { data: sectionsResult } = useGetSectionsListQuery({ relations: ['grid'] });
  const sections = sectionsResult?.results || [];

  const authorsParams = hasPermission ? {} : { employeeId };

  const { data: authors } = useGetAuthorsListQuery(
    { ...authorsParams, relations: [] },
    { skip: !employeeId },
  );

  const initialValues = getInitialValues(post);
  const [debouncedValues] = useDebounce(formValues, 500);

  const isPublished = isPostPublished(status);
  const isVisibleAlias = isArticle(type);

  const sectionsOptions = sections.filter(({ postTypes }) => postTypes.includes(currentType));

  useEffect(() => {
    const values = getUpdateValues(debouncedValues);
    form
      .validateFields()
      .then(() => {
        updatePost({ ...values, id: postId });
      })
      .catch(noop);
  }, [form, postId, updatePost, debouncedValues]);

  const sectionFilter = (
    input: string,
    option: {
      title: string;
    },
  ) => isDefined(option) && option.title.toLowerCase().includes(input.toLowerCase());

  return (
    <Row gutter={[0, 25]} className={styles.settingsWrapper} justify="space-between">
      <Col span={24}>
        <Form
          form={form}
          layout="inline"
          initialValues={initialValues}
          style={{ marginRight: '-15px' }}
        >
          <Col xs={24} md={6}>
            <Form.Item
              name="sections"
              label="Рубрика"
              labelCol={{ span: 24 }}
              rules={[{ message: 'Выберите рубрику', required: true }]}
              getValueFromEvent={(sectionId) => [].concat(sectionId)}
            >
              <Select
                showSearch
                filterOption={sectionFilter}
                size="large"
                disabled={isSectionDisabled(currentType)}
                placeholder="Выбрать"
                options={sectionsOptions}
                maxTagCount="responsive"
                fieldNames={{ label: 'title', value: 'id' }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={6}>
            <Form.Item name="publishedAt" label="Дата публикации" labelCol={{ span: 24 }}>
              <DatePicker
                disabled={isPublished}
                style={{ width: '100%' }}
                size="large"
                showNow
                showTime
                format="dd.MM.yyyy, HH:mm"
                allowClear
                placeholder="Сейчас"
              />
            </Form.Item>
          </Col>

          <Visible isVisible={isVisibleAlias}>
            <Col xs={24} md={6}>
              <Form.Item
                name="alias"
                label="Алиас"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    message: 'Задайте корректный алиас',
                    pattern: /^[0-9a-zA-Z-]+$/,
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={isPublished}
                  style={{ height: '40px', width: '100%' }}
                  className={styles.input}
                  placeholder="Добавьте алиас"
                  autoComplete="off"
                  suffix={
                    <Tooltip title="Используйте символы латиницы, цифры или дефис">
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
          </Visible>
        </Form>
      </Col>
    </Row>
  );
};
