import { always, cond, defaultTo, indexOf, lte, pipe, T } from 'ramda';

export enum WidgetType {
  facebook = 'facebook',
  inst = 'inst',
  newsmedia = 'newsmedia',
  script = 'script',
  twitter = 'twitter',
}

export const getTypeWidget = cond([
  [pipe(defaultTo(''), indexOf(WidgetType.newsmedia), lte(0)), always(WidgetType.newsmedia)],
  [pipe(defaultTo(''), indexOf(WidgetType.script), lte(0)), always(WidgetType.script)],
  [pipe(defaultTo(''), indexOf(WidgetType.inst), lte(0)), always(WidgetType.inst)],
  [pipe(defaultTo(''), indexOf(WidgetType.twitter), lte(0)), always(WidgetType.twitter)],
  [pipe(defaultTo(''), indexOf(WidgetType.facebook), lte(0)), always(WidgetType.facebook)],
  [T, always(null)],
]);
