import { GridSchemaSecure } from 'a4bd-meta';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';

import { updateManyCurrentGridSchemas } from '~slices';
import { deleteMarkup } from '~utils';

import { searchPostsModal } from '../../../SearchPostModal';
import { EmptyBlock, PostBlock } from '../components';
import styles from './styles.module.scss';

type Props = GridSchemaSecure & {
  onChange?(gridSchema: Partial<GridSchemaSecure>): Partial<GridSchemaSecure>;
};

export const MainSlider: FC<PropsWithChildren<Props>> = (props) => {
  const { filterByPostTypes, filterBySectionId, grid, id, onChange, posts } = props;
  const dispatch = useDispatch();

  const filledPosts = Array(3)
    .fill(undefined)
    .map((data, i) => {
      if (posts[i]) return posts[i];
      return undefined;
    });

  return (
    <Col flex="785px">
      <Row className={styles.mainSlider}>
        {filledPosts.map((post, i) => {
          const className = classNames(
            styles.sliderWrapper,
            i === 0 && styles.firstSlide,
            i === 2 && styles.lastSlide,
          );

          const handleChangePost = async () => {
            const newPost = await searchPostsModal({
              maxPostCount: 1,
              modalTitle: 'Добавление публикации',
              sections: [filterBySectionId, ...(grid ? [grid.id] : [])],
              types: filterByPostTypes,
            });

            const newPosts = [...filledPosts];
            // eslint-disable-next-line prefer-destructuring
            newPosts[i] = newPost[0];

            const data = { posts: newPosts };
            const changes = onChange ? onChange(data) : data;
            dispatch(updateManyCurrentGridSchemas([{ changes, id }]));
          };

          const handleDeletePost = () => {
            const newPosts = [...filledPosts];
            // eslint-disable-next-line prefer-destructuring
            newPosts[i] = undefined;
            const data = { posts: newPosts };
            const changes = onChange ? onChange(data) : data;
            dispatch(updateManyCurrentGridSchemas([{ changes, id }]));
          };

          if (!post) {
            return (
              <Col span={8} className={className}>
                <div className={styles.slide}>
                  <EmptyBlock slideName={`Слайдер ${i + 1}`} onAddPost={handleChangePost} />
                </div>
              </Col>
            );
          }

          const { preview, title, type } = post;

          return (
            <Col span={8} className={className}>
              <div className={styles.slide}>
                <PostBlock
                  title={deleteMarkup(title?.text)}
                  slideName={`Слайдер ${i + 1}`}
                  type={type}
                  preview={preview.imageURLs.origin.share.desktop}
                  isDeletable
                  onChangePost={handleChangePost}
                  onDeletePost={handleDeletePost}
                />
              </div>
            </Col>
          );
        })}
      </Row>
    </Col>
  );
};
