import { GridSchemaSecure } from 'a4bd-meta';
import { Button, Col, Row } from 'antd';
import { not, pick } from 'ramda';
import React, { ComponentProps, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GridSchemaCard, TitlePage } from '~components';
import { MobilePageHeader, ModelList, PageHeader } from '~containers';
import { useNotification } from '~hooks';
import { changesGridSchemasEntitySelector, gridSchemasEntitySelector } from '~selectors';
import {
  useCreateGridSchemaMutation,
  useGetGridSchemasListQuery,
  useGetGridsListQuery,
  useUpdateGridSchemasMutation,
} from '~services';
import { updateManyGridSchemas } from '~slices';

import { GridSchemaModal } from '../../containers/GridSchemaModal';
import styles from './styles.module.scss';

interface RightContentProps {
  onReset?(): void;
}

const RightContent: React.FC<RightContentProps> = ({ onReset }) => {
  const changes = useSelector(changesGridSchemasEntitySelector);

  const gridSchemas = useSelector(gridSchemasEntitySelector.selectAll);
  const [updateGridSchemas, { isLoading }] = useUpdateGridSchemasMutation();

  const hasChanges = changes.length > 0;

  const notification = useNotification();

  const publishHandler = async () => {
    const result = await updateGridSchemas({
      items: gridSchemas
        .filter(({ id }) => changes.includes(id))
        .map(
          pick<keyof GridSchemaSecure>([
            'id',
            'gridId',
            'title',
            'layout',
            'canChangeOrder',
            'canHide',
            'canChangePosts',
            'canPaginate',
            'isFilterByCurrentSection',
            'isHidden',
            'filterByPostTypes',
            'maxPostCount',
          ]),
        ),
    });

    if ('data' in result) {
      notification({
        message: 'Изменения опубликованы',
        placement: 'top',
      });
    }
  };

  return (
    <Row gutter={[25, 0]}>
      <Col>
        <Button size="large" disabled={!hasChanges} onClick={onReset}>
          Отменить
        </Button>
      </Col>
      <Col>
        <Button
          size="large"
          type="primary"
          disabled={!hasChanges}
          loading={isLoading}
          onClick={publishHandler}
        >
          Опубликовать
        </Button>
      </Col>
    </Row>
  );
};

export const GridSchemasPage: React.FC = () => {
  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>();
  const { data, isFetching, refetch } = useGetGridSchemasListQuery({
    offset,
    relations: ['posts', 'grid'],
    title: searchTerm,
  });
  const dispatch = useDispatch();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [gridSchema, setGridSchema] = useState<GridSchemaSecure>();
  const items = useSelector(gridSchemasEntitySelector.selectAll);

  useGetGridsListQuery({});
  const [createGridSchema] = useCreateGridSchemaMutation();

  const toggleModal = () => {
    setIsShowModal(not);
  };

  const handleCreate = () => {
    setGridSchema(undefined);
    toggleModal();
  };

  const handleEdit = (gridSchema: GridSchemaSecure) => {
    setGridSchema(gridSchema);
    toggleModal();
  };

  const handleSortEnd: ComponentProps<typeof ModelList>['onSortEnd'] = (changes) => {
    dispatch(updateManyGridSchemas(changes));
  };

  const handleFetch = async () => {
    setOffset(items?.length);
  };

  const handleReset = async () => {
    setOffset(0);
    refetch();
  };

  const handleCloseModal = async (gridSchema?: Partial<GridSchemaSecure>) => {
    if (gridSchema) {
      const { id, ...data } = gridSchema;

      if (id) {
        dispatch(updateManyGridSchemas([{ changes: data, id }]));
      } else {
        await createGridSchema(data);
      }
    }

    toggleModal();
  };

  useEffect(() => {
    refetch();
  }, [refetch, searchTerm]);

  return (
    <Row gutter={[0, 24]}>
      <Col span={24} className={styles.mobilePageHeader}>
        <MobilePageHeader
          title="Управление схемами сеток"
          onCreate={handleCreate}
          placeholderText="Поиск"
          onSearch={setSearchTerm}
        />
      </Col>
      <Col span={24} className={styles.pageHeader}>
        <PageHeader
          placeholderText="Поиск"
          createButtonText="Создать схему сетки"
          onCreate={handleCreate}
          onSearch={setSearchTerm}
        />
      </Col>
      <Col span={24}>
        <TitlePage
          title="Управление схемами сеток"
          rightContent={<RightContent onReset={handleReset} />}
        />
      </Col>
      <Col span={24}>
        <ModelList
          onEdit={handleEdit}
          items={items}
          count={data?.count}
          isFetching={isFetching}
          card={GridSchemaCard}
          onSortEnd={handleSortEnd}
          onFetch={handleFetch}
        />
        <GridSchemaModal isOpen={isShowModal} onClose={handleCloseModal} gridSchema={gridSchema} />
      </Col>
    </Row>
  );
};
