import { PostType, SectionAlias } from 'a4bd-meta';
import { Col, MenuProps, Row, Space } from 'antd';
import classNames from 'classnames';
import {
  __,
  always,
  compose,
  cond,
  equals,
  includes,
  isEmpty,
  not,
  omit,
  pathEq,
  pipe,
  prop,
  T,
} from 'ramda';
import React, { ComponentProps, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { objectToSearchString, searchStringToObject } from 'serialize-query-params';

import { Filter } from '~assets';
import { Badge, Button, Sort, SortField, Switch, TitlePage } from '~components';
import { BadgeColor, RoutesPath, SortDirections } from '~constants';
import { MobilePageHeader, PageHeader, PostsFilter, PostsList } from '~containers';
import { UserContext } from '~providers';
import { countPostsSelector } from '~selectors';
import { useCreatePostMutation, useGetAuthorsListQuery, useGetSectionsListQuery } from '~services';
import { isDefined, isSuccessResult, sortToString } from '~utils';

import styles from './styles.module.scss';

interface Props {
  type?: PostType;
}

type FilterValue = ComponentProps<typeof PostsFilter>['values'];

const sortFields: SortField[] = [
  { label: 'По дате создания', value: 'createdAt' },
  { label: 'По дате публикации', value: 'publishedAt' },
];

const getCreateTitle = cond([
  [equals(PostType.Article), always('Новая статья')],
  [equals(PostType.Story), always('Новая история')],
  [equals(PostType.Thing), always('Новая штука')],
  [equals(PostType.Podcast), always('Новый подкаст')],
  [equals(PostType.Custom), always('Новая инфостраница')],
  [T, always('Новая публикация')],
]);

const getTitle = cond([
  [equals(PostType.Article), always('Все статьи')],
  [equals(PostType.Story), always('Все истории')],
  [equals(PostType.Podcast), always('Все подкасты')],
  [equals(PostType.Thing), always('Все штуки')],
  [T, always('Все публикации')],
]);

const menuItems: MenuProps['items'] = [
  { key: PostType.Article, label: 'Статья' },
  { key: PostType.Story, label: 'История' },
  { key: PostType.Podcast, label: 'Подкаст' },
  { key: PostType.Thing, label: 'Штука' },
  { key: PostType.Custom, label: 'Инфостраница' },
];

export const PostsPage: React.FC<Props> = ({ type }) => {
  const isMountRef = useRef(true);
  const { search } = useLocation();
  const { user } = useContext(UserContext);
  const initialQueryParams = searchStringToObject(search);
  const [viewFilter, setViewFilter] = useState(!isEmpty(initialQueryParams));
  const [isCardType, setIsCardType] = useState(true);
  const [isOnlyMyPosts, setIsOnlyMyPosts] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<FilterValue>({ type, ...initialQueryParams });
  const [order, setOrder] = useState<Record<string, SortDirections>>({
    createdAt: SortDirections.Desc,
  });
  const [createPost] = useCreatePostMutation();
  const { data: sectionsResponse } = useGetSectionsListQuery({ relations: ['grid'] });
  const { data: authorsResponse } = useGetAuthorsListQuery({});

  const navigate = useNavigate();
  const sections = sectionsResponse?.results ?? [];
  const authors = authorsResponse?.results ?? [];
  const myAuthorId = authors.find(pathEq(['employee', 'id'], user?.id))?.id;
  const count = useSelector(countPostsSelector);

  const toggleViewFilter = () => {
    setViewFilter(not);
  };

  const getOnCreateSectionIds = (type: PostType): number[] => {
    switch (type) {
      case PostType.Podcast:
        return sections
          .filter(pipe(prop('alias'), includes(__, [SectionAlias.Podcasts])))
          .map(prop<number>('id'));
      case PostType.Story:
        return sections
          .filter(pipe(prop('alias'), includes(__, [SectionAlias.Stories])))
          .map(prop<number>('id'));
      case PostType.Thing:
        return sections
          .filter(pipe(prop('alias'), includes(__, [SectionAlias.Things])))
          .map(prop<number>('id'));
      default:
        return [];
    }
  };

  const handleCreate = async ({ key }: any) => {
    const postType = type || key;
    const result = await createPost({
      alias: null,
      authorIds: myAuthorId ? [myAuthorId] : [],
      feedIds: [],
      isAffiliateArticle: false,
      isAuthorVisible: false,
      isModificationDateVisible: false,
      sectionIds: getOnCreateSectionIds(postType),
      tagIds: [],
      type: postType,
    });

    if (isSuccessResult(result)) {
      navigate(generatePath(RoutesPath.PostEdit, { id: result?.data?.id }));
    }
  };

  const resetFilter = useCallback(() => {
    setFilter({});
  }, []);

  const handleChange = (values: FilterValue) => {
    setFilter((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };
  const handleSearch = (term: string) => {
    setFilter((prevValues) => ({ ...prevValues, term }));
  };

  const menuDropdown: MenuProps = {
    items: menuItems,
    onClick: handleCreate,
  };

  const handleOnlyMyPosts = () => {
    const shouldShowOnlyMyPosts = !isOnlyMyPosts;
    setIsOnlyMyPosts(shouldShowOnlyMyPosts);
    if (shouldShowOnlyMyPosts) {
      if (myAuthorId) {
        setFilter((prevValues) => ({ ...prevValues, authors: [myAuthorId] }));
      }
    } else {
      setFilter((prevValues) => ({
        ...prevValues,
        authors: ([] as number[])
          .concat(prevValues?.authors ?? [])
          .map((authorId) => parseInt(`${authorId}`, 10))
          .filter(compose(not, equals(__, myAuthorId))),
      }));
    }
  };

  useEffect(() => {
    // При переходе через главное меню срабатывает этот эффект, но при первом запуске его нужно пропустить, чтобы не
    // сбросились фильтры из querystring
    if (isMountRef.current) {
      isMountRef.current = false;
    } else {
      resetFilter();
    }
  }, [resetFilter, type]);

  useEffect(() => {
    const omitKeys = [];
    // Для новостей не нужно передавать тип в querystring
    if (type) {
      omitKeys.push('type');
    }
    setSearchParams(objectToSearchString(omit(omitKeys, filter)));

    // После сброса формы необходимо снова установить дефолтный фильтр
    if (isEmpty(filter)) {
      setFilter({ type });
    }
  }, [filter, setSearchParams, type]);

  const activeFilters =
    filter && Object.values(filter).filter((value) => !!value && value.length !== 0).length;

  return (
    <Row gutter={[0, 24]}>
      <Col span={24} className={styles.mobilePageHeader}>
        <MobilePageHeader
          title={getTitle(type)}
          titlePostfix={
            <Badge color={BadgeColor.BlueLight} size="small">
              {count ?? 0}
            </Badge>
          }
          placeholderText="Поиск"
          onCreate={handleCreate}
          onSearch={handleSearch}
          term={filter && filter.term}
          menuDropdown={type ? undefined : menuDropdown}
        />
      </Col>
      <Col span={24} className={styles.pageHeader}>
        <PageHeader
          placeholderText="Поиск"
          createButtonText={getCreateTitle(type)}
          onCreate={handleCreate}
          onSearch={handleSearch}
          isCardType={isCardType}
          setIsCardType={setIsCardType}
          term={filter && filter.term}
          menuDropdown={type ? undefined : menuDropdown}
        />
      </Col>
      <Col span={24}>
        <TitlePage
          title={getTitle(type)}
          titlePostfix={
            <Badge color={BadgeColor.BlueLight} size="large">
              {count ?? 0}
            </Badge>
          }
          rightContent={
            <Space>
              <Switch
                disabled={!myAuthorId}
                isChecked={isOnlyMyPosts}
                setIsChecked={handleOnlyMyPosts}
                prefix="Только мои посты"
              />
              <div>
                <Button
                  size="large"
                  icon={
                    <Filter
                      className={classNames(styles.filterIcon, viewFilter && styles.selected)}
                    />
                  }
                  onClick={toggleViewFilter}
                  className={classNames(styles.filterButton, viewFilter && styles.active)}
                />
                {activeFilters !== 0 && <div className={styles.activeFilters}>{activeFilters}</div>}
              </div>
            </Space>
          }
        />
      </Col>
      {viewFilter && (
        <Col span={24}>
          <PostsFilter
            values={filter}
            isFixedType={isDefined(type)}
            onChange={handleChange}
            resetFilter={resetFilter}
          />
        </Col>
      )}
      <Col span={24}>
        <Sort fields={sortFields} value={order} onChange={setOrder} />
      </Col>
      <Col span={24}>
        <PostsList filter={filter} order={sortToString(order)} isCardType={isCardType} />
      </Col>
    </Row>
  );
};
