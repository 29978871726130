import { SectionPreviewSecure } from 'a4bd-meta';
import { Button, Col, Row } from 'antd';
import { not, pick } from 'ramda';
import React, { ComponentProps, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SectionCard, TitlePage } from '~components';
import { MobilePageHeader, ModelList, PageHeader, SectionModal } from '~containers';
import { useNotification } from '~hooks';
import { changesSectionsEntitySelector, sectionsEntitySelector } from '~selectors';
import {
  useCreateSectionMutation,
  useGetGridsListQuery,
  useGetSectionsListQuery,
  useUpdateSectionsMutation,
} from '~services';
import { updateManySections } from '~slices';

import styles from './styles.module.scss';

interface RightContentProps {
  onReset?(): void;
}

const RightContent: React.FC<RightContentProps> = ({ onReset }) => {
  const changes = useSelector(changesSectionsEntitySelector);

  const sections = useSelector(sectionsEntitySelector.selectAll);
  const [updateSections, { isLoading }] = useUpdateSectionsMutation();

  const hasChanges = changes.length > 0;

  const notification = useNotification();

  const publishHandler = async () => {
    const result = await updateSections({
      items: sections
        .filter(({ id }) => changes.includes(id))
        .map(
          pick<keyof SectionPreviewSecure>([
            'id',
            'sortOrder',
            'status',
            'title',
            'isShownInMenu',
            'isShownInFooter',
            'gridId',
            'postTypes',
            'subtitle',
            'alias',
            'darkColor',
            'lightColor',
          ]),
        ),
    });

    if ('data' in result) {
      notification({
        message: 'Изменения опубликованы',
        placement: 'top',
      });
    }
  };

  return (
    <Row gutter={[25, 0]}>
      <Col>
        <Button size="large" disabled={!hasChanges} onClick={onReset}>
          Отменить
        </Button>
      </Col>
      <Col>
        <Button
          size="large"
          type="primary"
          disabled={!hasChanges}
          loading={isLoading}
          onClick={publishHandler}
        >
          Опубликовать
        </Button>
      </Col>
    </Row>
  );
};

export const SectionsPage: React.FC = () => {
  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>();
  const { data, isFetching, refetch } = useGetSectionsListQuery({
    offset,
    relations: ['grid'],
    title: searchTerm,
  });
  const dispatch = useDispatch();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [section, setSection] = useState<SectionPreviewSecure>();
  const items = useSelector(sectionsEntitySelector.selectAll);

  useGetGridsListQuery({});
  const [createSection] = useCreateSectionMutation();

  const toggleModal = () => {
    setIsShowModal(not);
  };

  const handleCreate = () => {
    setSection(undefined);
    toggleModal();
  };

  const handleEdit = (section: SectionPreviewSecure) => {
    setSection(section);
    toggleModal();
  };

  const handleSortEnd: ComponentProps<typeof ModelList>['onSortEnd'] = (changes) => {
    dispatch(updateManySections(changes));
  };

  const handleFetch = async () => {
    setOffset(items?.length);
  };

  const handleReset = async () => {
    setOffset(0);
    refetch();
  };

  const handleCloseModal = async (section?: Partial<SectionPreviewSecure>) => {
    if (section) {
      const { id, ...data } = section;

      if (id) {
        dispatch(updateManySections([{ changes: data, id }]));
      } else {
        await createSection(data);
      }
    }

    toggleModal();
  };

  useEffect(() => {
    refetch();
  }, [refetch, searchTerm]);

  return (
    <Row gutter={[0, 24]}>
      <Col span={24} className={styles.mobilePageHeader}>
        <MobilePageHeader
          title="Управление разделами"
          onCreate={handleCreate}
          placeholderText="Поиск"
          onSearch={setSearchTerm}
        />
      </Col>
      <Col span={24} className={styles.pageHeader}>
        <PageHeader
          placeholderText="Поиск"
          createButtonText="Создать раздел"
          onCreate={handleCreate}
          onSearch={setSearchTerm}
        />
      </Col>
      <Col span={24}>
        <TitlePage
          title="Управление разделами"
          rightContent={<RightContent onReset={handleReset} />}
        />
      </Col>
      <Col span={24}>
        <ModelList
          onEdit={handleEdit}
          items={items}
          count={data?.count}
          isFetching={isFetching}
          card={SectionCard}
          onSortEnd={handleSortEnd}
          onFetch={handleFetch}
        />
        <SectionModal isOpen={isShowModal} onClose={handleCloseModal} section={section} />
      </Col>
    </Row>
  );
};
