import { SectionSecure } from 'a4bd-meta';
import { Col, Row } from 'antd';
import { stringify } from 'query-string';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Edit } from '~assets';
import { Button } from '~components';
import { InfoPagesTypes, RoutesPath } from '~constants';

import styles from './styles.module.scss';

type InfoPageCardProps = {
  postId: number;
  section: SectionSecure;
};

export const InfoPageCard: React.FC<InfoPageCardProps> = ({ postId, section }) => {
  const { alias, title } = section;
  const navigate = useNavigate();

  const onEdit = () =>
    navigate(
      `${RoutesPath.Settings}?${stringify({
        postId,
        type: InfoPagesTypes.InfoPage,
      })}`,
    );

  return (
    <div className={styles.wrapper}>
      <Col span={24}>
        <Row align="middle" justify="space-between" wrap={false}>
          <Col className={styles.infoWrapper}>
            <Row className={styles.title}>{title}</Row>
            <Row className={styles.subtitle}>{`URL: /s/${alias}`}</Row>
          </Col>
          <Col>
            <Button className={styles.editButton} onClick={onEdit}>
              <Edit />
              Редактировать
            </Button>
          </Col>
        </Row>
      </Col>
    </div>
  );
};
